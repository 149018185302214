import React from "react";
import Container from "../components/Container";
import techGuy from "../images/tech-guy.png";
import shape from "../images/shape-1.png";
import shape2 from "../images/shape-2.png";
import shape3 from "../images/shape-3.png";
import shape4 from "../images/shape-4.png";
import shape5 from "../images/shape-5.png";
import shape6 from "../images/shape-6.png";

export default function ContactIf() {
  const ifList = [
    { id: 1, img: shape, content: "jesteś na początku drogi biznesowej" },
    {
      id: 2,
      img: shape2,
      content: "jesteś na początku poszukiwania/ dobierania nowych narzędzi IT",
    },
    { id: 3, img: shape3, content: "Twój biznes skaluje w większym wymiarze" },
    {
      id: 4,
      img: shape4,
      content: "Twój biznes wymaga „odświeżenia” technologicznego",
    },
    {
      id: 5,
      img: shape5,
      content: "chcesz rozwijać się na rynkach lokalnych/EU",
    },
    { id: 6, img: shape6, content: "chcesz zwiększyć swoją konkurencyjność" },
  ];

  return (
    <Container className="pt-contactIf-clamp-pt pb-contactIf-clamp-pb">
      <h2 className="text-h2 mb-contactIf-clamp-h-mb text-center font-semibold">
        skontaktuj się z nami jeśli:
      </h2>
      <div className="grid grid-cols-1 items-center justify-items-center gap-x-10 gap-y-14 xl:grid-cols-2">
        <img src={techGuy} alt="tech-guy" className="w-full max-w-[730px]" />
        <ul className="w-full max-w-[800px] space-y-5">
          {ifList.map(({ id, img, content }) => (
            <li key={id} className="flex items-baseline gap-x-7">
              <img src={img} alt="shape" />
              <p className="text-p-lg">{content}</p>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
}
