import PropTypes from "prop-types";

function ScrollDown({ firstSection }) {
  const handleBtnClick = () =>
    firstSection.current.scrollIntoView({ behavior: "smooth" });

  return (
    <div className="absolute bottom-7 left-1/2 z-20 w-max -translate-x-1/2 -translate-y-full -rotate-90 overflow-hidden text-gray">
      <div className="h-[2px] animate-scrollDown bg-gradient-to-r from-cyan to-pink" />
      <button type="button" className="text-xl" onClick={handleBtnClick}>
        SCROLL
      </button>
    </div>
  );
}

ScrollDown.propTypes = {
  firstSection: PropTypes.node.isRequired,
};

export default ScrollDown;
