import React, { forwardRef } from "react";
import Container from "../components/Container";
import ContactButtons from "../components/ContactButtons";

const Contact = forwardRef((_, ref) => (
  <section className="bg-darker" ref={ref}>
    <Container className="pb-contact-clamp-pb pt-contact-clamp-pt">
      <h2 className="mb-contact-clamp-h2-mb text-center text-h2 font-bold">
        sprawdź jak możesz poprawić swoje rozwiązania IT
      </h2>
      <p className="mb-20 text-center text-h4 font-semibold text-gray">
        przeanalizujmy Twoją sytuacje
      </p>
      <ContactButtons />
    </Container>
  </section>
));

export default Contact;
