import PropTypes from "prop-types";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

const Container = forwardRef(({ className, children }, ref) => (
  <div className={twMerge("container mx-auto px-6", className)} ref={ref}>
    {children}
  </div>
));

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Container.defaultProps = {
  className: null,
};

export default Container;
