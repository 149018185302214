import React, { forwardRef } from "react";
import Container from "../components/Container";
import faqsBg from "../images/faqs-bg.png";
import rectangle from "../images/rectangle.png";
import Accordion from "../components/Accordion";

const Faqs = forwardRef((_, ref) => (
  <section className="relative z-10" ref={ref}>
    <img
      src={faqsBg}
      alt="faqs-bg"
      className="absolute inset-0 -z-10 h-full w-full object-cover"
    />
    <Container className="space-y-marquee-py pb-faq-pb pt-faq-pt">
      <p className="text-p-sm font-semibold text-dark-gray">FAQS</p>
      <h2 className="w-max bg-gradient-to-r from-cyan to-violet bg-clip-text text-h2 font-bold leading-[1.4] text-transparent">
        co zyskujesz
      </h2>
      <div className="flex flex-wrap items-center justify-center gap-y-28 2xl:justify-between">
        <img src={rectangle} alt="rectangle" className="max-[590px]" />
        <Accordion />
      </div>
    </Container>
  </section>
));

export default Faqs;
