import PropTypes from "prop-types";
import React, { useState } from "react";
import Container from "../components/Container";
import HamburgerMenu from "../components/HamburgerMenu";
import logo from "../images/nav-logo.png";

export default function Header({ sections }) {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      document.body.style.overflow = "unset";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  const navLinks = [
    "co robimy",
    "dla kogo",
    "proces współpracy",
    "twoje korzyści",
    "kim jesteśmy",
  ];

  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLinkClick = (e, i) => {
    e.preventDefault();
    const thisSection = sections[i].current;
    thisSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <header className="sticky top-0 z-50 bg-gradient-to-b from-darker from-60% to-[rgba(255,255,255,0)]">
      <Container className="flex w-full max-w-[1600px] items-center justify-between py-11">
        <button
          type="button"
          className="w-nav-logo-clamp-w"
          onClick={handleLogoClick}
        >
          <img src={logo} alt="bitedigital" />
        </button>
        <HamburgerMenu isOpen={isOpen} handleOpen={handleOpen} />
        <ul className="hidden w-full max-w-[880px] justify-between px-6 xl:flex">
          {navLinks.map((navLink, i) => (
            <li>
              <button
                type="button"
                className="text-[22px]"
                onClick={(e) => handleLinkClick(e, i)}
              >
                {navLink}
              </button>
            </li>
          ))}
        </ul>
        <button
          type="button"
          className="hidden rounded-full bg-gradient-to-r from-cyan via-violet to-rose px-6 py-3 text-sm font-semibold xl:flex"
          onClick={(e) => handleLinkClick(e, sections.length - 1)}
        >
          POROZMAWIAJMY
        </button>
        {/* MOBILE */}
        <div
          className={`absolute left-0 top-0 z-10 flex h-screen w-full flex-col bg-darker px-6 py-11 xl:hidden ${isOpen ? "translate-x-0 duration-300" : "-translate-x-full duration-300"}`}
        >
          <img
            src={logo}
            alt="bitedigital"
            className="mb-10 w-nav-logo-clamp-w"
          />
          <ul className="mb-10 space-y-5">
            {navLinks.map((navLink, i) => (
              <li>
                <button
                  type="button"
                  className="text-[22px]"
                  onClick={(e) => {
                    handleOpen();
                    handleLinkClick(e, i);
                  }}
                >
                  {navLink}
                </button>
              </li>
            ))}
          </ul>
          <button
            type="button"
            className="w-max rounded-full bg-gradient-to-r from-cyan via-violet to-rose px-6 py-3 text-sm font-semibold xl:hidden"
            onClick={(e) => handleLinkClick(e, sections.length - 1)}
          >
            POROZMAWIAJMY
          </button>
        </div>
      </Container>
    </header>
  );
}

Header.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.node)).isRequired,
};
