import React from "react";
import bitedigital from "../images/bitedigital.png";
import video from "../video/interlude-bg.mp4";
import Container from "../components/Container";

export default function Interlude() {
  return (
    <section className="relative">
      <video
        className="min-h-[640px] w-full object-cover"
        autoPlay
        loop
        muted
        playsInline
        src={video}
        type="video/mp4"
      />
      <Container className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 justify-center">
        <img src={bitedigital} alt="bitedigital" />
      </Container>
    </section>
  );
}
