import React, { forwardRef } from "react";
import Container from "../components/Container";
import WebServices from "../components/WebServices";
import AppServices from "../components/AppServices";

const BusinessSupport = forwardRef((_, ref) => (
  <Container className="py-6 pb-12" ref={ref}>
    <div className="mb-web-app-gap">
      <h2 className="mb-10 text-center text-h2 font-semibold">
        jak wspieramy
        <br />
        Twój biznes
      </h2>
      <p className="mx-auto max-w-[675px] text-center text-p-md font-semibold">
        Specjalizujemy się w wsparciu Klientówz sektora małych i średnich firm w
        procesie tworzenia spersonalizowanych produktów IT.
      </p>
    </div>
    <div className="space-y-web-app-gap">
      <WebServices />
      <AppServices />
    </div>
  </Container>
));

export default BusinessSupport;
