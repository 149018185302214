import React from "react";
import greenEllipse from "../images/green-ellipse.png";

export default function WebServices() {
  const servicesList = [
    "Wykup domeny",
    "Omówienie design",
    "Ustalenie sekcji",
    "Przygotowanie treści",
    "Implementacja załączników",
    "Integracja z system",
    "Testy",
    "Wsparcie serwisowe",
  ];
  return (
    <div>
      <div className="mb-web-app-gap flex flex-col items-center">
        <h2 className="text-h2 font-bold text-cyan">Website</h2>
        <h2 className="text-h2 font-bold text-blue">Landing Page</h2>
        <h2 className="text-h2 font-bold text-violet">E-commerce</h2>
      </div>
      <div className="flex flex-wrap justify-center gap-x-10">
        <div className="relative mb-6">
          <p className="text-p-md w-full font-semibold text-gray xl:sticky xl:right-1/4 xl:top-32 xl:max-w-[440px]">
            Projektujemy, tworzymy i zapewniamy wsparcie w ciągłym rozwoju
            oryginalnych domen internetowych, które są wizytówką Twojej firmy
            dając najlepsze doznania Twoim Klientom.
          </p>
        </div>
        <ul className="w-full max-w-[670px]">
          {servicesList.map((el) => (
            <li key={el} className="flex items-baseline gap-x-ellipse-gap">
              <img
                src={greenEllipse}
                alt="green-ellipse"
                className="w-ellipse-size"
              />
              <p className="text-h4 font-semibold">{el}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
