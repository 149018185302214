import React, { forwardRef } from "react";
import Container from "../components/Container";

const About = forwardRef((_, ref) => (
  <Container
    className="grid w-full max-w-[1680px] grid-cols-1 py-20 xl:grid-cols-2 xl:grid-rows-[1fr,0.5fr]"
    ref={ref}
  >
    <div className="rounded-[100px] border-[5px] border-cyan px-about-clamp-div-px py-about-clamp-div-py">
      <h3 className="mb-5 text-h3 font-bold">Co nas cechuje</h3>
      <p className=" mb-10 text-p-sm font-semibold text-gray">
        Wyróżnia nas biznesowe podejście do Klienta, które jest ukształtowane
        przez nasz zespół.
      </p>
      <p className=" text-p-sm font-semibold text-gray">
        Doświadczenie technologiczne, a także operacyjne pozwala nam na
        dopasowanie produktów/usług do potrzeb klienta skupiając się na
        maksymalizacji efektów końcowych przy jednoczesnym zapewnieniu
        konkurencyjności technologicznej.
      </p>
    </div>
    <div className="rounded-[100px] bg-dark px-about-clamp-div-px py-about-clamp-div-py xl:col-start-2 xl:row-start-2">
      <h3 className="mb-5 text-h3 font-bold">Nasza misja</h3>
      <p className=" mb-10 text-p-sm font-semibold text-gray">
        Strategia naszych działań jest ukierunkowana na rozwój lokalnych
        przedsiębiorców zapewniając dostęp do nowoczesnych narzędzi
        technologicznych.
      </p>
    </div>
  </Container>
));

export default About;
