import React from "react";
import pinkEllipse from "../images/pink-ellipse.png";

export default function AppServices() {
  const servicesList = [
    "Edukacja na temat aplikacji",
    "Omówienie funkcjonalności",
    "Plusy i minusy",
    "Przygotowanie strategii",
    "UX/UI Design",
    "Testowanie",
    "Wsparcie serwisowe",
  ];
  return (
    <div>
      <div className="mb-web-app-gap flex flex-col items-center">
        <h2 className="text-h2 font-bold text-pink">Mobile App</h2>
        <h2 className="text-h2 font-bold text-rose">Web App</h2>
      </div>
      <div className="flex flex-wrap justify-center gap-x-10">
        <div className="relative mb-6">
          <p className="text-p-md w-full font-semibold text-gray xl:sticky xl:right-1/4 xl:top-32 xl:max-w-[440px]">
            Efektywnie doradzamy oraz implikujemy pomysły technologiczne w życie
            naszych Klientów. Jesteśmy odpowiedzialni od początkowej fazy
            koncepcji, aż po implementacje aplikacji oraz jej serwisowanie.
          </p>
        </div>
        <ul className="w-full max-w-[670px]">
          {servicesList.map((el) => (
            <li key={el} className="flex items-baseline gap-x-ellipse-gap">
              <img
                src={pinkEllipse}
                alt="green-ellipse"
                className="w-ellipse-size"
              />
              <p className="text-h4 font-semibold">{el}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
