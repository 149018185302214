import { useRef } from "react";
import About from "./views/About";
import BusinessSupport from "./views/BusinessSupport";
import Coop from "./views/Co-op";
import Contact from "./views/Contact";
import ContactIf from "./views/ContactIf";
import Faqs from "./views/Faqs";
import Footer from "./views/Footer";
import Header from "./views/Header";
import Hero from "./views/Hero";
import Interlude from "./views/Interlude";
import MarqueeScroll from "./views/MarqueeScroll";
import Specialization from "./views/Specialization";
import Team from "./views/Team";

function App() {
  // TODO: context
  const BusinessSupportRef = useRef(null);
  const SpecializationRef = useRef(null);
  const CoopRef = useRef(null);
  const FaqsRef = useRef(null);
  const AboutRef = useRef(null);
  const ContactRef = useRef(null);
  const sections = [
    BusinessSupportRef,
    SpecializationRef,
    CoopRef,
    FaqsRef,
    AboutRef,
    ContactRef,
  ];

  return (
    <>
      <Header sections={sections} />
      <Hero firstSection={BusinessSupportRef} />
      <div className="relative bg-darker">
        <BusinessSupport ref={BusinessSupportRef} />
        <Specialization ref={SpecializationRef} />
        <ContactIf />
        <Coop ref={CoopRef} />
        <MarqueeScroll />
        <Faqs ref={FaqsRef} />
        <About ref={AboutRef} />
        <Team />
        <Interlude />
        <Contact ref={ContactRef} />
        <Footer />
      </div>
    </>
  );
}

export default App;
