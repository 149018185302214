import React from "react";
import kuba from "../images/kuba.jpg";
import bruno from "../images/bruno.jpg";
import grzesiek from "../images/grzesiek.jpg";
import igor from "../images/igor.jpg";

export default function Team() {
  const biteSquad = [
    {
      id: 1,
      picture: kuba,
      name: "kuba",
      profession: "Sales",
      desc: "Wyróżnia nas biznesowe podejście do Klienta, które jest ukształtowane przez nasz zespół. ",
    },
    {
      id: 2,
      picture: bruno,
      name: "bruno",
      profession: "Full-Stack Developer",
      desc: "wieloletni staż oraz doświadczenie w różnorodnych projektach, pozwala zbudować szyte na miarę aplikację dostosowane do potrzeb klienta",
    },
    {
      id: 3,
      picture: grzesiek,
      name: "grzesiek",
      profession: "UI UX Designer",
      desc: "dąży do zapewnienia jak najlepszej całości wrażeń, jakich doświadcza użytkownik podczas korzystania z produktu, dzięki ciągłemu podążaniu za aktualnymi trendami projektowym",
    },
    {
      id: 4,
      picture: igor,
      name: "igor",
      profession: "Front-End Developer",
      desc: "transformuję pomysły w estetyczne i funkcjonalne witryny internetowe",
    },
  ];

  return (
    <section className="mx-auto w-full max-w-[1920px] py-32">
      <h2 className="mb-team-h-mb text-h2 font-semibold md:text-center">
        kim jesteśmy
      </h2>
      <ul className="grid grid-cols-1 gap-y-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {biteSquad.map(({ id, picture, profession, desc }) => (
          <li key={id} className="px-6 sm:px-0 sm:even:pt-14">
            <img src={picture} alt="member" className="mb-8 w-full" />
            <dl className="sm:px-5">
              <dt className="mb-3 text-p-md font-semibold">{profession}</dt>
              <dd className="text-p-sm  text-gray">{desc}</dd>
            </dl>
          </li>
        ))}
      </ul>
    </section>
  );
}
