import React from "react";
import globe from "../images/globe.png";
import logo from "../images/logo.png";
import email from "../images/email.png";
import phone from "../images/phone.png";
import location from "../images/location.png";
import github from "../images/github.png";
import discord from "../images/discord.png";
import instagram from "../images/instagram.png";
import facebook from "../images/facebook.png";
import linkedin from "../images/linkedin.png";
import Container from "../components/Container";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const contact = [
    { id: 1, icon: email, content: "kuba@bitedigital.io" },
    { id: 2, icon: phone, content: "+48 575 575 575" },
  ];
  const socialMedia = [
    { id: 1, icon: github, link: "/#" },
    { id: 2, icon: discord, link: "/#" },
    { id: 3, icon: instagram, link: "/#" },
    { id: 4, icon: facebook, link: "/#" },
    { id: 5, icon: linkedin, link: "/#" },
  ];
  const footerLinks = [
    "Terms & Conditions",
    "Privacy policy",
    "Important Notice",
  ];

  return (
    <footer className="bg-dark">
      <Container className="relative max-w-[1542px] pb-footer-clamp-pb pt-48 text-[24px]">
        <img
          src={globe}
          alt="globe"
          className="w-globe-clamp-w absolute -top-64 right-6 md:-top-56"
        />
        <span className="z-50 mb-14 block h-[3px] w-full bg-gradient-to-r from-cyan via-violet to-rose " />
        <img src={logo} alt="bitedigital" className="mb-12" />
        <ul className="mb-36 flex w-full max-w-[380px] flex-col justify-start font-semibold">
          {contact.map(({ id, icon, content }) => (
            <li
              key={id}
              className="flex items-center gap-x-4 border px-4 py-4 sm:gap-x-8"
            >
              <img src={icon} alt="contact" />
              <p>{content}</p>
            </li>
          ))}
        </ul>
        <div className="mb-12 grid grid-cols-1 gap-y-12 md:grid-cols-2">
          <ul className="mr-auto flex w-full max-w-[480px] items-center justify-between md:col-start-2 lg:ml-auto lg:mr-0">
            {socialMedia.map(({ id, icon, link }) => (
              <li key={id}>
                <a href={link}>
                  <img src={icon} alt="social" />
                </a>
              </li>
            ))}
          </ul>
          <div className="flex items-center gap-x-6 md:col-start-1 md:row-start-1">
            <img src={location} alt="ping" className="h-6 w-6" />
            <p className="text-[24px] font-semibold">Warszawa, Polska.</p>
          </div>
        </div>
        <span className="mb-14 block h-[3px] w-full bg-gradient-to-r from-cyan via-violet to-rose" />
        <div className="grid grid-cols-1 gap-y-14 lg:grid-cols-[1fr,2fr]">
          <ul className="flex flex-wrap justify-center gap-x-10 gap-y-11  sm:justify-start lg:col-start-2  lg:justify-end">
            {footerLinks.map((el) => (
              <li key={el}>
                <a className="font-medium text-gray" href="/#">
                  {el}
                </a>
              </li>
            ))}
          </ul>
          <p className=" font-semibold text-gray lg:col-start-1  lg:row-start-1">
            © {currentYear} bitedigital.
          </p>
        </div>
      </Container>
    </footer>
  );
}
