import React from "react";
import PropTypes from "prop-types";
import paragraph from "../images/paragraph.png";

export default function ArticleContent({ children }) {
  return (
    <div className="w-full max-w-[615px]">
      <img src={paragraph} alt="paragraph" className="-translate-x-6" />
      <p className="text-p-md mt-10 font-semibold">{children}</p>
    </div>
  );
}
ArticleContent.propTypes = {
  children: PropTypes.node.isRequired,
};
