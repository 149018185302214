import React, { useState } from "react";
import blackArrow from "../images/black-arrow.png";
import whiteArrow from "../images/white-arrow.png";
import PopUp from "../views/PopUp";

export default function ContactButtons() {
  const [isOpen, setIsOpen] = useState(false);
  const handlePopUpToggle = (id) => {
    if (id === 1) return;
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const buttons = [
    {
      id: 1,
      content: "kawa online",
      img: blackArrow,
      text: "dla chcących podjąć współpracę w terminie do 1 miesiąca",
      gradientColor: "from-rose to-violet",
      textColor: "text-dark",
    },
    {
      id: 2,
      content: "napisz do nas",
      img: whiteArrow,
      text: "jeśli jesteś na etapie planowania podjęcia współpracy",
      gradientColor: "from-violet to-cyan",
      textColor: "text-white",
    },
  ];

  return (
    <ul className="grid-col-1 grid justify-items-center gap-x-24 gap-y-16 xl:grid-cols-2">
      {buttons.map(({ id, content, img, gradientColor, textColor, text }) => (
        <li key={id} className="max-w-min">
          <button
            type="button"
            onClick={() => handlePopUpToggle(id)}
            onKeyDown={() => handlePopUpToggle(id)}
            className={`${gradientColor} mx-auto mb-8 flex min-w-max items-center justify-center gap-2.5 rounded-full bg-gradient-to-r px-[72px] py-[22px]`}
          >
            <p className={`${textColor} text-h4 font-semibold`}>{content}</p>
            <img src={img} alt="Arrow" />
          </button>
          <p className="text-p-sm font-semibold text-gray xl:px-[72px]">
            {text}
          </p>
        </li>
      ))}
      <PopUp
        onPopUpToggle={handlePopUpToggle}
        className={`fixed inset-0 z-50 h-screen w-full  bg-darker ${isOpen ? "scale-100 duration-200" : "scale-0 duration-200"}`}
      />
    </ul>
  );
}
