import PropTypes from "prop-types";
import Container from "../components/Container";
import ScrollDown from "../components/ScrollDown";
import video from "../video/hero-bg.mp4";

function Hero({ firstSection }) {
  return (
    <Container className="relative flex min-h-screen justify-center overflow-hidden">
      <video
        className="fixed inset-0 -z-1 min-h-screen w-full object-cover opacity-50"
        autoPlay
        loop
        muted
        playsInline
        src={video}
        type="video/mp4"
      />
      <div className="space-y-hero-h1-clamp-gap">
        <div>
          <h1 className="text-center text-h1 font-semibold">projektujemy</h1>
          <h1 className="text-end text-h1 font-semibold">budujemy</h1>
          <h1 className="text-center text-h1 font-semibold">pożeramy</h1>
        </div>
        <h1 className="w-max bg-gradient-to-r from-cyan via-violet to-rose text-custom-h1  font-semibold text-black">
          produkty cyfrowe
        </h1>
      </div>
      <ScrollDown firstSection={firstSection} />
      <span className="absolute bottom-0 left-0 block w-screen bg-gradient-to-t from-darker to-transparent pt-6" />
    </Container>
  );
}

Hero.propTypes = {
  firstSection: PropTypes.node.isRequired,
};

export default Hero;
