import React, { useState } from "react";
import pinkArrow from "../images/pink-arrow.png";
import greenArrow from "../images/green-arrow.png";

export default function Accordion() {
  const [activeTab, setActiveTab] = useState(1);

  const tabs = [
    {
      id: 1,
      title: "Transparentność",
      content:
        "Uważamy, że każdy biznes prowadzi się w sposób otwarty, dlatego komunikacje z Klientem dostosowujemy do realiów biznesowych, przedstawiając realne zagrożenia oraz szanse każdego projektu.",
    },
    {
      id: 2,
      title: "Jakość",
      content:
        "Uważamy, że każdy biznes prowadzi się w sposób otwarty, dlatego komunikacje z Klientem dostosowujemy do realiów biznesowych, przedstawiając realne zagrożenia oraz szanse każdego projektu.",
    },
    {
      id: 3,
      title: "Innowacyjność",
      content:
        "Uważamy, że każdy biznes prowadzi się w sposób otwarty, dlatego komunikacje z Klientem dostosowujemy do realiów biznesowych, przedstawiając realne zagrożenia oraz szanse każdego projektu.",
    },
    {
      id: 4,
      title: "Doświadczenie",
      content:
        "Uważamy, że każdy biznes prowadzi się w sposób otwarty, dlatego komunikacje z Klientem dostosowujemy do realiów biznesowych, przedstawiając realne zagrożenia oraz szanse każdego projektu.",
    },
    {
      id: 5,
      title: "Relacje",
      content:
        "Uważamy, że każdy biznes prowadzi się w sposób otwarty, dlatego komunikacje z Klientem dostosowujemy do realiów biznesowych, przedstawiając realne zagrożenia oraz szanse każdego projektu.",
    },
  ];

  return (
    <ul className="w-full max-w-[730px] space-y-12">
      {tabs.map(({ id, title, content }) => (
        <li key={id} className="">
          <button
            type="button"
            className="mb-8 flex w-full items-center justify-between"
            onClick={() => setActiveTab(id)}
          >
            <p className="text-h4 font-semibold">{title}</p>
            <img src={activeTab === id ? pinkArrow : greenArrow} alt="arrow" />
          </button>
          <div
            className={`${activeTab === id ? "block" : "hidden"} border-b border-white pb-6 text-lg`}
          >
            {content}
          </div>
        </li>
      ))}
    </ul>
  );
}
