import React from "react";
import Marquee from "react-fast-marquee";
import cross from "../images/cross.png";

export default function MarqueeScroll() {
  const list = ["UI/UX", "Web", "App", "Ecommerce"];
  return (
    <Marquee autoFill>
      <ul className="py-marquee-py gap-x-marquee-gap-x ml-marquee-gap-x flex">
        {list.map((el) => (
          <li key={el} className="gap-x-marquee-gap-x flex items-center">
            <img src={cross} alt="cross" className="h-[60px] w-[60px]" />
            <p className="text-marquee font-semibold text-gray">{el}</p>
          </li>
        ))}
      </ul>
    </Marquee>
  );
}
