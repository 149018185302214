import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

export default function HamburgerMenu({ isOpen, handleOpen }) {
  HamburgerMenu.propTypes = {
    handleOpen: PropTypes.isRequired,
    isOpen: PropTypes.isRequired,
  };

  const variantUp = {
    open: {
      y: 7,
      rotate: 45,
      transition: { rotate: { delay: 0.3 } },
    },
    closed: {
      y: 0,
      rotate: 0,
      transition: { y: { delay: 0.3 } },
    },
  };
  const variantDown = {
    open: {
      y: -7,
      rotate: -45,
      transition: { rotate: { delay: 0.3 } },
    },
    closed: {
      y: 0,
      rotate: 0,
      transition: { y: { delay: 0.3 } },
    },
  };

  return (
    <button
      onClick={handleOpen}
      onKeyDown={handleOpen}
      type="button"
      aria-label="Hamburger menu"
      className="absolute right-5 z-20 flex h-7 w-8 flex-col justify-around lg:hidden"
    >
      <motion.span
        className="block h-[2px] w-full bg-gray"
        animate={isOpen ? "open" : "closed"}
        variants={variantUp}
      />
      <motion.span
        className="block h-[2px] w-full bg-gray"
        animate={isOpen ? "open" : "closed"}
        variants={variantDown}
      />
    </button>
  );
}
