import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import logo from "../images/nav-logo.png";
import pixelX from "../images/pixel-cross.png";
import rightArrow from "../images/arrow-circle-right.png";
import formBg from "../images/form-bg.png";
import Container from "../components/Container";

export default function PopUp({ className, onPopUpToggle }) {
  PopUp.propTypes = {
    className: PropTypes.isRequired,
    onPopUpToggle: PropTypes.isRequired,
  };

  return (
    <section className={twMerge(className)}>
      <img
        src={formBg}
        alt="sphere"
        className="absolute right-0 top-0 -z-1 h-[100%] opacity-50"
      />
      <Container className="py-10">
        <div className="mb-4 flex items-center justify-between md:mb-0">
          <img src={logo} alt="bitedigital" className="w-nav-logo-clamp-w" />
          <button
            type="button"
            onClick={onPopUpToggle}
            onKeyDown={onPopUpToggle}
          >
            <img src={pixelX} alt="x button" />
          </button>
        </div>
        <div className="flex flex-col gap-x-16 lg:flex-row">
          <h2 className="w-max bg-gradient-to-r from-cyan to-violet bg-clip-text text-h2 font-bold  text-transparent">
            napisz
            <br /> do nas
          </h2>
          <form action="post" className=" w-full max-w-[500px] space-y-[15px]">
            <label htmlFor="name" className="flex flex-col">
              <p className="mb-2">imię</p>
              <div className="bg-gradient-to-r from-cyan via-violet to-rose p-[2px]">
                <input
                  type="text"
                  id="name"
                  placeholder="Łukasz"
                  className="w-full bg-darker px-6 py-4 text-white outline-none"
                />
              </div>
            </label>
            <label htmlFor="email" className="flex flex-col">
              <p className="mb-2">adres e-mail</p>
              <div className="bg-gradient-to-r from-cyan via-violet to-rose p-[2px]">
                <input
                  type="email"
                  id="email"
                  placeholder="adres@gmail.com"
                  className="w-full bg-darker px-6 py-4 text-white outline-none"
                />
              </div>
            </label>
            <label htmlFor="wiadomość" className="flex flex-col">
              <p className="mb-2">wiadomość</p>
              <div className="bg-gradient-to-r from-cyan via-violet to-rose p-[2px]">
                <textarea
                  type="text"
                  id="wiadomość"
                  placeholder="Witam, jestem zainteresowany..
              Potrzebuje wsparcia w…
              Moje dane kontaktowe to…"
                  className="min-h-[180px] w-full resize-none bg-darker px-6 py-4 text-white outline-none"
                />
              </div>
            </label>
            <p className="inset-4 text-sm">
              Wysyłając, zgadzasz się z{" "}
              <span className="underline">Regulaminem</span> bitedigital i{" "}
              <span className="underline">Polityką prywatności.</span> Dbamy o
              Twoją prywatność. Gwarantowany brak sprzedaży e-mailowej i
              spamowania.
            </p>
            <button
              type="button"
              className="flex w-full max-w-[226px] items-center justify-center rounded-full bg-gradient-to-r from-violet to-cyan"
            >
              <p className="ml-6 text-[22px]">wyślij</p>
              <img src={rightArrow} alt="arrow pointing right" />
            </button>
          </form>
        </div>
      </Container>
    </section>
  );
}
