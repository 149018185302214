import React from "react";
import PropTypes from "prop-types";

export default function ArticleHeading({ children }) {
  return (
    <p className="text-p-sm mb-12 font-semibold text-dark-gray">{children}</p>
  );
}
ArticleHeading.propTypes = {
  children: PropTypes.node.isRequired,
};
