import React, { forwardRef } from "react";
import Container from "../components/Container";
import blue from "../images/lb-border-blue.png";
import violet from "../images/lb-border-violet.png";
import pink from "../images/lb-border.png";
import ArticleHeading from "../components/ArticleHeading";
import ArticleContent from "../components/ArticleContent";

const Coop = forwardRef((_, ref) => {
  const list = [
    {
      id: 1,
      no: "01",
      color: "text-cyan",
      heading: "Ocena możliwość",
      content:
        "30 minutowe spotkanie pozwala nam na analizę Twojej sytuacjii przedstawienie dostępnych możliwości",
      picture: pink,
    },
    {
      id: 2,
      no: "02",
      color: "text-dark-cyan",
      heading: "Analiza",
      content:
        "Po spotkaniu otrzymasz podsumowanie Twojej sytuacji wraz z najważniejszymi ustaleniami oraz wstępną ofertą",
      picture: blue,
    },
    {
      id: 3,
      no: "03",
      color: "text-blue",
      heading: "Konsultacja",
      content:
        "Dopasowanie się do potrzeb biznesowych, a także realna ocena sytuacji wymaga od nas analizy, dlatego ofertę wraz z najważniejszym czynnikami współpracy omawiamy na kolejnym spotkaniu",
      picture: pink,
    },
    {
      id: 4,
      no: "04",
      color: "text-violet",
      heading: "Plan",
      content:
        "Względem Twoich oczekiwań oraz ustaleń opracujemy harmonogram pracy",
      picture: violet,
    },
    {
      id: 5,
      no: "05",
      color: "text-light-violet",
      heading: "Weryfikacja prac",
      content:
        "Raz w tygodniu otrzymujesz od nas pełen raport na temat przeprowadzonych działań, abyś mógł kontrolować cały proces",
      picture: pink,
    },
    {
      id: 6,
      no: "06",
      color: "text-pink",
      heading: "Testowanie",
      content:
        "Jeśli projekt jest gotowy i spełnia wszystkie porządne funkcjonalności przystępujemy do testowania, aby Twoje rozwiązanie działało poprawnie",
      picture: pink,
    },
    {
      id: 7,
      no: "07",
      color: "text-rose",
      heading: "Partnerstwo",
      content:
        "Każda współpracę traktujemy po partnersku, dlatego wraz z Tobą ustalamy formę wsparcia, a także cykliczne spotkania raz na kwartał",
      picture: pink,
    },
  ];

  return (
    <section className="bg-darker" ref={ref}>
      <Container>
        <h2 className="mb-coop-clamp-h-mb text-h2 font-semibold">
          proces współpracy
        </h2>
        <ArticleHeading>THE COOPERATIVE PROCESS</ArticleHeading>
        <ArticleContent>
          The cooperative process embodies collaborative synergy, where diverse
          skills and perspectives unite to achieve shared success. It fuels
          innovation and efficiency through collective effort and understanding
        </ArticleContent>
        <ul className="space-y-12">
          {list.map(({ id, no, heading, content, color }) => (
            <li
              key={id}
              className="relative flex flex-col items-center justify-center gap-y-10 xl:flex-row xl:items-end xl:even:flex-row-reverse"
            >
              <p
                className={`w-max text-p-xl font-bold leading-[0.95] ${color}`}
              >
                {no}
              </p>
              <div className="mb-3 w-full max-w-[1040px] rounded-[48px] border-[5px] border-cyan px-coop-clamp-div-px py-11">
                <h3 className="text-[40px] font-bold">{heading}</h3>
                <p className="text-p-md text-gray">{content}</p>
              </div>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
});

export default Coop;
