import React, { forwardRef } from "react";
import sectionBg from "../images/specialization-section-bg.png.png";
import containerBg from "../images/specialization-container-bg.png";
import Container from "../components/Container";
import ArticleHeading from "../components/ArticleHeading";
import ArticleContent from "../components/ArticleContent";

const Specialization = forwardRef((_, ref) => (
  <section className="relative z-10" ref={ref}>
    <img
      src={sectionBg}
      alt="pixels"
      className="absolute bottom-0 left-0 -z-10 h-full w-full object-cover"
    />
    <Container
      className="py-spec-py grid w-full max-w-[1650px] grid-cols-1 grid-rows-1 items-center gap-x-10
       gap-y-10 py-10 xl:grid-cols-[1fr,1fr,1fr] xl:grid-rows-[1.2fr,2fr,2fr]"
    >
      <div className="xl:col-[1/3] xl:row-[2/3]">
        <ArticleHeading>SPECJALIZACJA</ArticleHeading>
        <ArticleContent>
          Specjalizujemy się w projektach doradczych skierowanych do branży
          usługowej, chcącej rozwija swój biznes poprzez wykorzystanie
          nowoczesnych możliwości technologicznych.
        </ArticleContent>
      </div>
      <img
        src={containerBg}
        alt="services"
        className="w-full xl:col-[2/4] xl:row-[1/4]"
      />
    </Container>
  </section>
));

export default Specialization;
